/**
 * The geosuggest module
 */
.geosuggest {
  font-size: 18px;
  font-size: 1rem;
  position: relative;
  // width: 50%;

  text-align: left;
}
.geosuggest__input {
  width: 100%;
  border: 2px solid transparent;
  box-shadow: 0 0 1px #3d464d;
  padding: .5em 1em;
  -webkit-transition: border 0.2s, box-shadow 0.2s;
          transition: border 0.2s, box-shadow 0.2s;
}
.geosuggest__input:focus {
  border-color: #9CC435;
  box-shadow: 0 0 0 transparent;
}
.geosuggest__suggests {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 25em;
  padding: 0;
  margin-top: -1px;
  background: #fff;
  border: 2px solid #9CC435;
  border-top-width: 0;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  z-index: 6;
  -webkit-transition: max-height 0.2s, border 0.2s;
          transition: max-height 0.2s, border 0.2s;
}
.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0;
}

/**
 * A geosuggest item
 */
.geosuggest-item {
  font-size: 18px;
  font-size: 1rem;
  padding: .5em .65em;
  cursor: pointer;
  z-index:6;
  /* background-color: red; */
}
.geosuggest-item:hover,
.geosuggest-item:focus {
  background: #f5f5f5;
}
.geosuggest-item--active {
  background: #9CC435;
  color: #fff;
}
.geosuggest-item--active:hover,
.geosuggest-item--active:focus {
  background: #ccc;
}
